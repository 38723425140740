import { paxios } from "../../../utils/axios";

export const role = async () => {
  const url = "/user/role";
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};

export const getOne = async (id) => {
  const url = `/plan/${id}`;
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};
export const updateplan = async (
  _id,
  cardcolor,
  switchribbon,
  ribbon,
  titulo,
  precio,
  listopciones,
  Rutas,
  Marcadores,
  Reservas,
  Mangas,
  NAP_I,
  NAP_II,
  ONTS,
  limiteUsers,
  Dias,
  Postes
) => {
  try {
    const { data } = await paxios.post(`/plan/update/${_id}`, {
      id: _id,
      cardcolor: cardcolor,
      switchribbon: switchribbon,
      ribbon: ribbon,
      titulo: titulo,
      precio: precio,
      listopciones: listopciones,
      Rutas: Rutas,
      Marcadores: Marcadores,
      Reservas: Reservas,
      Mangas: Mangas,
      NAP_I: NAP_I,
      NAP_II: NAP_II,
      ONTS: ONTS,
      limiteUsers: limiteUsers,
      Dias: Dias,
      Postes: Postes,
    });
    return data;
  } catch (e) {
    throw e;
  }
};
