import React, { useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import styles from "./Form.module.sass";

// Components
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Card from "../../../../components/Card";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";
import ModalAlert from "../../../../components/ModalAlert";
import InfoAlert from "../../../../components/ModalAlert/InfoAlert";

// Axios
import { updateplan } from "../axios";

const optionsIcon = ["✅", "❌"];
const optionsRibbon = ["On", "Off"];
const optionsColor = ["💚 Verde", "💛 Amarillo", "💙 Azul", "🧡 Rojo"];

var modalData = {
  isOpen: false,
  iconName: "confetti",
  iconEmoji: "🚧",
  title: "Obligatorio",
  subtitle: "",
  message: `Necesitas agregar una opción al paquete.`,
  buttonCancel: "Aceptar",
};

const Form = ({
  formDataPlan,
  setFormDataPlan,
  selectIcon,
  setSelectIcon,
  selectColor,
  setSelectColor,
  selectRibbon,
  setSelectRibbon,
}) => {
  const navigate = useNavigate();

  const [visibleModal, setVisibleModal] = useState(false);

  const handlerColor = (value) => {
    switch (value) {
      case "💚 Verde":
        return "success";
      case "💙 Azul":
        return "primary";
      case "💛 Amarillo":
        return "warning";
      case "🧡 Rojo":
        return "danger";
      default:
        return "";
    }
  };

  const handlerRibbon = (value) => {
    switch (value) {
      case "On":
        return true;
      case "Off":
        return false;
      default:
        return false;
    }
  };

  const onchangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "precio") {
      if (value > 1000) {
        return 1000;
      }
      if (value === "") {
        return 0;
      }
    }

    setFormDataPlan((prev) => ({ ...prev, [name]: value }));
  };

  const optionHandler = () => {
    if (formDataPlan.opciones === "") {
      modalData = {
        isOpen: false,
        iconName: "confetti",
        iconEmoji: "🚧",
        title: "Obligatorio",
        subtitle: "",
        message: `Necesitas agregar una opción al paquete.`,
        buttonCancel: "Aceptar",
      };
      return setVisibleModal(true);
    }

    if (formDataPlan.listopciones.length >= 10) {
      modalData = {
        isOpen: false,
        iconName: "confetti",
        iconEmoji: "🚧",
        title: "¡Las listas están llenas!",
        subtitle: "",
        message: `Es el máximo de opciones por tarjeta.`,
        buttonCancel: "Aceptar",
      };
      return setVisibleModal(true);
    }

    let listAdd = {};
    listAdd.status = selectIcon;
    listAdd.lista = formDataPlan.opciones;

    setFormDataPlan((prev) => ({
      ...prev,
      listopciones: formDataPlan.listopciones.concat(listAdd),
    }));
    opctionClean();
  };

  const opctionClean = () => {
    setSelectIcon("✅");
    setFormDataPlan((prev) => ({ ...prev, opciones: "" }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (formDataPlan.listopciones.length) {
        let planupdate = await updateplan(
          formDataPlan.idplan,
          handlerColor(selectColor),
          handlerRibbon(selectRibbon),
          formDataPlan.ribbon,
          formDataPlan.titulo,
          formDataPlan.precio,
          formDataPlan.listopciones,
          formDataPlan.Rutas,
          formDataPlan.Marcadores,
          formDataPlan.Reservas,
          formDataPlan.Mangas,
          formDataPlan.NAP_I,
          formDataPlan.NAP_II,
          formDataPlan.ONTS,
          formDataPlan.limiteUsers,
          formDataPlan.Dias,
          formDataPlan.Postes,
        );
        if (planupdate.status === "UPDATED") {
          modalData = {
            isOpen: false,
            iconName: "confetti",
            iconEmoji: "✅",
            title: "Éxito",
            subtitle: "",
            message: `Se ha actualizado el Plan.`,
            buttonCancel: "Aceptar",
          };
          setVisibleModal(true);
          setTimeout(() => {
            setVisibleModal(false);
          }, 3000);

          setTimeout(() => {
            navigate("/admin/planes/list");
          }, 3500);
        }
        if (planupdate.status === "INVALID") {
          modalData = {
            isOpen: false,
            iconName: "confetti",
            iconEmoji: "❌",
            title: "Error",
            subtitle: "",
            message: `No se ha actualizado el plan. ${planupdate.msg}`,
            buttonCancel: "Aceptar",
          };
          setVisibleModal(true);
        }
      } else {
        modalData = {
          isOpen: false,
          iconName: "confetti",
          iconEmoji: "🚧",
          title: "¡La lista esta vacia!",
          subtitle: "",
          message: `Necesitas una opcion como mínimo.`,
          buttonCancel: "Aceptar",
        };
        return setVisibleModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Card className={styles.card} classTitle="title-green">
        <form className={styles.wrapper} onSubmit={onSubmit}>
          <Dropdown
            className={styles.field}
            label="Color de Tarjeta"
            tooltip="Color de Tarjeta"
            value={selectColor}
            setValue={setSelectColor}
            options={optionsColor}
          />
          <br />
          <Dropdown
            className={styles.field}
            label="Ribbon Promo Tag"
            tooltip="Ribbon Promo Tag"
            value={selectRibbon}
            setValue={setSelectRibbon}
            options={optionsRibbon}
          />
          <br />
          {selectRibbon === "On" && (
            <TextInput
              className={styles.field}
              label="Promo"
              name="ribbon"
              type="text"
              placeholder="Promo"
              tooltip="Más de 2 caracteres"
              required
              onChange={onchangeHandler}
              value={formDataPlan.ribbon}
              maxLength="12"
            />
          )}
          <TextInput
            className={styles.field}
            label="Título"
            name="titulo"
            type="text"
            placeholder="Título"
            tooltip="Más de 2 caracteres"
            required
            onChange={onchangeHandler}
            value={formDataPlan.titulo}
            maxLength="20"
          />
          <TextInput
            className={styles.field}
            label="Precio"
            name="precio"
            type="number"
            placeholder="Precio"
            tooltip="Más de 2 caracteres"
            onChange={onchangeHandler}
            value={formDataPlan.precio}
            currency="$"
            step={0.01}
            min={0}
            max={1000}
            maxLength={1}
            precision={2}
            pattern="\d*"
          />
          <hr />
          <br />
          <Dropdown
            className={styles.field}
            label="Opción de Icono"
            tooltip="Opción de Icono"
            value={selectIcon}
            setValue={setSelectIcon}
            options={optionsIcon}
          />
          <br />
          <TextInput
            className={styles.field}
            label="Lista de Opción"
            name="opciones"
            type="text"
            placeholder="Lista de Opción"
            tooltip="Más de 2 caracteres"
            onChange={onchangeHandler}
            value={formDataPlan.opciones}
            maxLength={50}
          />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              type="button"
              onClick={optionHandler}
              className={cn("button-stroke", styles.button)}
            >
              Agregar lista
            </button>
          </div>
          <br />
          <hr />
          <br />

          <div className={styles.rowtreecolums}>
            <TextInput
              className={styles.field}
              label="Rutas"
              name="Rutas"
              value={formDataPlan.Rutas}
              type="number"
              placeholder="Rutas"
              tooltip="Rutas"
              onChange={onchangeHandler}
              pattern="\d*"
              step={0}
              min={0}
              required
            />
            <TextInput
              className={styles.field}
              label="Marcadores"
              name="Marcadores"
              value={formDataPlan.Marcadores}
              type="number"
              placeholder="Marcadores"
              tooltip="Marcadores"
              onChange={onchangeHandler}
              pattern="\d*"
              step={0}
              min={0}
              required
            />

            <TextInput
              className={styles.field}
              label="Reservas"
              name="Reservas"
              value={formDataPlan.Reservas}
              type="number"
              placeholder="Reservas"
              tooltip="Reservas"
              onChange={onchangeHandler}
              pattern="\d*"
              step={0}
              min={0}
              required
            />
          </div>
          <div className={styles.rowtreecolums}>
            <TextInput
              className={styles.field}
              label="Mangas"
              name="Mangas"
              value={formDataPlan.Mangas}
              type="number"
              placeholder="Mangas"
              tooltip="Mangas"
              onChange={onchangeHandler}
              pattern="\d*"
              step={0}
              min={0}
              required
            />
            <TextInput
              className={styles.field}
              label="NAP_I"
              name="NAP_I"
              value={formDataPlan.NAP_I}
              type="number"
              placeholder="NAP_I"
              tooltip="NAP_I"
              onChange={onchangeHandler}
              pattern=".{2,}"
              step={0}
              min={0}
              required
            />

            <TextInput
              className={styles.field}
              label="NAP_II"
              name="NAP_II"
              value={formDataPlan.NAP_II}
              type="number"
              placeholder="NAP_II"
              tooltip="NAP_II"
              onChange={onchangeHandler}
              pattern=".{2,}"
              step={0}
              min={0}
              required
            />
          </div>
          <div className={styles.rowtreecolums}>
            <TextInput
              className={styles.field}
              label="ONTS"
              name="ONTS"
              value={formDataPlan.ONTS}
              type="number"
              placeholder="ONTS"
              tooltip="ONTS"
              onChange={onchangeHandler}
              pattern=".{2,}"
              step={0}
              min={0}
              required
            />
            <TextInput
              className={styles.field}
              label="Usuarios"
              name="limiteUsers"
              value={formDataPlan.limiteUsers}
              type="number"
              placeholder="Usuarios"
              tooltip="Usuarios"
              onChange={onchangeHandler}
              pattern=".{2,}"
              step={0}
              min={0}
              required
            />
            <TextInput
              className={styles.field}
              label="Días"
              name="Dias"
              value={formDataPlan.Dias}
              type="number"
              placeholder="Días"
              tooltip="Días"
              onChange={onchangeHandler}
              pattern=".{2,}"
              step={0}
              min={0}
              required
            />
          </div>
          <div className={styles.rowtreecolums}>
            <TextInput
              className={styles.field}
              label="Postes"
              name="Postes"
              value={formDataPlan.Postes}
              type="number"
              placeholder="Postes"
              tooltip="Postes"
              onChange={onchangeHandler}
              pattern=".{2,}"
              step={0}
              min={0}
              required
            />
          </div>

          <div style={{ display: "flex", justifyContent: "end" }}>
            <button type="submit" className={cn("button", styles.button)}>
              Actualizar Plan
            </button>
          </div>
        </form>
      </Card>

      <TooltipGlodal />
      <ModalAlert
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <InfoAlert
          modalData={modalData}
          onClose={() => setVisibleModal(false)}
        />
      </ModalAlert>
    </>
  );
};

export default Form;
